<template>
<div>
  <router-view v-show='this.$route.matched.length==3'></router-view>
  <PageHeaderLayout v-show='this.$route.matched.length==2'>
  </PageHeaderLayout>
</div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
export default {
  name: 'DataList',
  components: { PageHeaderLayout }
}
</script>

<style scoped>

</style>